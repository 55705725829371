html {
  background-color: #0e1116;
}

body {
  background: var(--surface);
  color: white !important;
  margin: 18px;
}

.container {}

.window-body {
  color: black !important;
}

.mb8 {
  margin-bottom: 8px;
}



.underline {
  text-decoration: underline;
}

.start-bar {
  width: 246px;
  height: 33px;
  float: left;
  position: relative;
  background: linear-gradient(71deg, navy, #1084d0);
  margin-right: 5px;
  transform: rotate(-90deg);
  left: 11px;
  transform-origin: left;
  top: 260px;
  color: white;
  font-weight: bold;
}

.window {
  margin: 8px;
}

 .footer
{
  display: flex;
  justify-content: left;
  margin-bottom: 10px;
  font-size: 17pt;
}

.footer > a{
  color: white;
  text-decoration: none;
}

.start-window > .window-body {
  margin: 0px !important;
}

.start-item {
  text-decoration: none;
  color:black;
}

.start-item > div {
  height: 34px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 3px;
}

.start-item:hover>div {
  color: white;
  background-color: navy;
}

.start-img {
  width: 34px;
  float: left;
}

.start-window > div > div > hr{
  margin: 0px;
}

.start-text {
  width: calc(100% - 45px);
  float: right;
  padding-top: 8px

}

/* 
.start-item{
  padding: 6px;
  color: black;  
  height: 33px;
}

.start-item {
  padding: 6px;
  color: black;
  height: 33px;
}

.start-item:hover > div {
  color: white;
  background-color: navy;
}

.start-item:hover > a {
  color: white;
}

.start-item > a{
  text-decoration: none;
  color: black;
} */

.form-validation{
  color: #cc0000;
}


.borderedTable{
  border: 1px solid white;
}

.borderedTable td {
  border: 1px solid white;
  padding: 8px;
}

.borderedTable th {
  border: 1px solid white;
  padding: 8px;
}

input[type=text] {
  
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
}

.container{
  padding: 24px;
}

.footer a{
  color: white !important;
}